body {
  background-color: #282c34;
  color: white;
  font-family: 'League Spartan', sans-serif;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-family: 'League Spartan', sans-serif;
  font-weight: 100;
  text-transform: uppercase;
}

h1 {
  font-size: 400%;
}

h2 {
  font-size: 300%;
}
h3 {
  font-size: 200%;
}

a,
a:visited {
  color: #49a1e5;
}

.App {
  text-align: center;
}

.whipsong-logo {
  max-width: 40vmax;
  /* height: 20vmin; */
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  /* min-height: 80vh; */
  max-width: 80vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
